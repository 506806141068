import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import testimonialsImage1 from "../assets/img/testimonials/testimonials-1.jpg";
import testimonialsImage2 from "../assets/img/testimonials/testimonials-2.jpg";
import testimonialsImage3 from "../assets/img/testimonials/testimonials-3.jpg";
import testimonialsImage4 from "../assets/img/testimonials/testimonials-4.jpg";
import testimonialsImage5 from "../assets/img/testimonials/testimonials-5.jpg";

function Testimonials() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1388,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const testimonials = [
    {
      clientName: "Karim A",
      clientRole: "Marketing Director",
      clientImage: testimonialsImage1,
      testimonialText:
        "Ryientsoft Technologies is a true innovator in the tech industry. Their solutions have transformed our business operations, providing us with scalable and efficient software that meets our unique needs. I highly recommend Ryientsoft for their expertise and dedication to excellence.",
    },
    {
      clientName: " Ola K",
      clientRole: "CEO",
      clientImage: testimonialsImage2,
      testimonialText:
        "Working with Ryientsoft has been a game-changer for our company. Their team understands our vision and has delivered exceptional results. Ryientsoft's commitment to quality and innovation sets them apart in the competitive landscape.",
    },
    {
      clientName: " Cristian F",
      clientRole: "Operations Manager",
      clientImage: testimonialsImage3,
      testimonialText:
        "I am impressed by Ryientsoft's ability to deliver cutting-edge solutions that drive our business forward. Their expertise in software development and dedication to customer satisfaction make them a valuable partner for any organization.",
    },
    {
      clientName: " Daniel K",
      clientRole: "CTO",
      clientImage: testimonialsImage4,
      testimonialText:
        "Ryientsoft Technologies has exceeded our expectations with their innovative approach and technical expertise. They have helped us achieve significant improvements in efficiency and performance. I highly recommend Ryientsoft for their professionalism and commitment to excellence.",
    },
    {
      clientName: "Olivia Wilson",
      clientRole: "Project Manager",
      clientImage: testimonialsImage5,
      testimonialText:
        "Our experience with Ryientsoft has been exceptional. They have provided us with customized solutions that have streamlined our operations and enhanced our productivity. Ryientsoft is a trusted partner for delivering high-quality software solutions.",
    },
  ];

  return (
    <section id="testimonials" className="testimonials">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2 className="gradient-text-color">Client Testimonials</h2>
          <p className="text-center">See what our clients are saying about us:</p>
        </div>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div className="swiper-slide" key={index}>
              <div className="testimonial-wrap">
                <div className="testimonial-item">
                  <div className="d-flex align-items-center">
                    <img
                      src={testimonial.clientImage}
                      className="testimonial-img flex-shrink-0"
                      alt={`Testimonial from ${testimonial.clientName}`}
                    />
                    <div className="testimonial-details">
                      <h3>{testimonial.clientName}</h3>
                      <h4>{testimonial.clientRole}</h4>
                      <div className="stars">
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                      </div>
                    </div>
                  </div>
                  <p className="testimonial-details">
                    <i className="bi bi-quote quote-icon-left"></i>
                    <span className="testimonial-text"> {testimonial.testimonialText}</span>
                    <i className="bi bi-quote quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default Testimonials;
