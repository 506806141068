import React, { useState } from "react";

function Enquiry() {
  const [formData, setFormData] = useState({
    name: "",
    phone_number: "",
    message: "",
    requirement: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://dev-api.trimlyxpert.com/api/v1/enquiries", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ enquiry: formData }),
      });

      if (response.ok) {
        const data = await response.json();
        alert("Enquiry submitted successfully!");
        setFormData({
          name: "",
          phone_number: "",
          message: "",
          requirement: "",
        });
      } else {
        alert("Failed to submit enquiry. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting enquiry:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  return (
    <div>
      <style>
        {`
          .btn-get-started {
            font-family: var(--font-primary);
            font-weight: 500;
            font-size: 15px;
            letter-spacing: 1px;
            display: inline-block;
            padding: 14px 40px;
            border-radius: 50px;
            transition: 0.3s;
            color: #fff;
            background-image: linear-gradient(#9f005d, #D94B93);
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
            border: 2px solid rgba(255, 255, 255, 0.1);
          }

          .btn-get-started:hover {
            border-color: #cb69a2;
            background: #cb69a2;
          }
        `}
      </style>
      <div className="">
        <form className="p-3 rounded" onSubmit={handleSubmit}>
          <div className="mb-4">
            <h5 className="fw-bold mb-4">Enquiry Details</h5>
            <div className="row mb-3">
              <div className="col-md-6 mb-1 py-1">
                <label className="form-label fw-bold h6">Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Your Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6 mb-1 py-1">
                <label className="form-label fw-bold h6">Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Your Phone Number"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6 mb-1 py-1">
                <label className="form-label fw-bold h6">Select Your Requirement</label>
                <select
                  className="form-select"
                  name="requirement"
                  value={formData.requirement}
                  onChange={handleChange}
                  required
                >
                  <option value="Web Application">Web Application</option>
                  <option value="Mobile Application">Mobile Application</option>
                  <option value="Both - Web & Mobile Application">Both - Web & Mobile Application</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="col-md-6 mb-1 py-1">
                <label className="form-label fw-bold h6">Message</label>
                <textarea
                  className="form-control"
                  placeholder="Enter Your Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <button type="submit" className="btn-get-started">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Enquiry;
