import React from "react";
import { Link } from "react-router-dom";
import Enquiry from "./Enquiry";

function Contact() {
  return (
    <>
      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Contact</h2>
            <p>
              We're here to help you navigate your technology needs and bring
              your vision to life. Whether you have a project in mind, need
              expert advice, or want to learn more about our services, our team
              is ready to assist you.
            </p>
          </div>

          <div className="row gx-lg-0 gy-4">
            <Enquiry />
            <div className="col-lg-6">
              <div className="info-container d-flex flex-column align-items-center justify-content-center">
                <div className="info-item d-flex">
                  <i className="bi bi-geo-alt flex-shrink-0"></i>
                  <div>
                    <h4>Location:</h4>
                    <p>
                      <Link
                        className="text-decoration-none text-white"
                        to="https://www.google.com/maps/place/28%C2%B037'36.0%22N+77%C2%B022'40.6%22E/@28.6266667,77.3779444,17z/data=!3m1!4b1!4m4!3m3!8m2!3d28.6266667!4d77.3779444?entry=ttu"
                        target="_blank"
                      >
                        H96, BS-7, Sector-63, Noida, Uttar Pradesh, 201301
                      </Link>
                    </p>
                  </div>
                </div>
                {/* End Info Item */}

                <div className="info-item d-flex">
                  <i className="bi bi-envelope flex-shrink-0"></i>
                  <div>
                    <h4>Email:</h4>
                    <p>
                      <Link
                        className="text-decoration-none text-white"
                        to="mailto:info@ryientsoft.com"
                      >
                        info@ryientsoft.com
                      </Link>
                    </p>
                  </div>
                </div>
                {/* End Info Item */}
              </div>
            </div>

            {/* <div className="col-lg-8"> */}
            {/* <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                </div>
                <div className="form-group mt-3">
                  <textarea className="form-control" name="message" rows="7" placeholder="Message" required></textarea>
                </div>
                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">Your message has been sent. Thank you!</div>
                </div>
                <div className="text-center"><button type="submit">Send Message</button></div>
              </form> */}
            <div className="col-lg-6" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100 rounded overflow-hidden">
                <iframe
                  style={{ width: "100%", height: "100%", border: "0" }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3437.691216938612!2d77.3753695!3d28.6266714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfb47ebd599c3%3A0x8fb7b6c27fdf3f1b!2s28%C2%B037'36.0%22N%2077%C2%B022'40.6%22E!5e0!3m2!1sen!2sin!4v1595419485960!5m2!1sen!2sin"
                  allowfullscreen=""
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
                <Link
                  to="https://www.google.com/maps/place/28%C2%B037'36.0%22N+77%C2%B022'40.6%22E/@28.6266667,77.3779444,17z/data=!3m1!4b1!4m4!3m3!8m2!3d28.6266667!4d77.3779444?entry=ttu"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 1,
                    backgroundColor: "transparent",
                    cursor: "pointer",
                  }}
                ></Link>
              </div>
            </div>
            {/* </div> */}
            {/* End Contact Form */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
