import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import RY4 from "../assets/img/ry4.webp";

function Footer() {
  const currentYear = new Date().getFullYear();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <section id="footer" className="footer top-box-border">
        <div className="container">
          <div className="row gy-1">
            <div className="col-lg-5 col-md-12 section-header">
              <Link to="/" className="logo d-flex align-items-center">
                <img src={RY4} alt="" style={{ height: "100px" }} />
              </Link>
              <p>
                Transform your enterprise with our innovative services designed
                to streamline your operations and propel you into the digital
                age. Embrace a new way of doing business with tailored solutions
                that drive efficiency, enhance productivity, and deliver
                unmatched value.
              </p>
            </div>
            {/* color: #6f6f6f; */}
            <div className="col-lg-2 col-6 footer-links">
              <h4 className="gradient-text-color">Useful Links</h4>
              <ul>
                <li>
                  <Link className="text-decoration-none" to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/about">
                    About us
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/domainservices">
                    Domain services
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/portfolio">
                    Portfolio
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/digitalmarketing">
                    Digital marketing
                  </Link>
                </li>
              </ul>
            </div>

            {/* <div className="col-lg-2 col-6 footer-links">
              <h4 className="gradient-text-color">Our Services</h4>
              <ul>
                <li>
                  <Link className="text-decoration-none" to="#AppDesign">
                    Web Design
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="#AppDesign">
                    Mobile App Design
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="#AppDesign">
                    Enterprise Application Design
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="#AppDesign">
                    Storyboard & Animation Design
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="#AppDesign">
                    Mobile App Design
                  </Link>
                </li>
              </ul>
            </div> */}

            <div className="col-lg-3 col-md-12 footer-contact text-md-start footer-links">
              <h4
                className="footer-he gradient-text-color"
                style={{ marginTop: "-14px" }}
              >
                Contact Us
              </h4>
              <ul>
                <li>
                  <Link
                    className="text-decoration-none"
                    to="https://www.google.com/maps/place/28%C2%B037'36.0%22N+77%C2%B022'40.6%22E/@28.6266667,77.3779444,17z/data=!3m1!4b1!4m4!3m3!8m2!3d28.6266667!4d77.3779444?entry=ttu"
                    target="_blank"
                  >
                    H96, BS-7, Sector-63, Noida, Uttar Pradesh, 201301
                  </Link>
                </li>
                <li>
                  <strong className="gradient-text-color">Email: </strong>
                  <Link
                    className="text-decoration-none text-center"
                    to="mailto:info@ryientsoft.com"
                  >
                    info@ryientsoft.com
                  </Link>
                </li>
              </ul>
              <div className="social-links d-flex mt-4  text-center">
                <Link
                  to="https://x.com/ryientsoft?t=yfN4uoxXyNn-THlrFP6RtQ&s=08"
                  target="_blank"
                  className="twitter gradient-text-color"
                >
                  <i className="bi bi-twitter-x"></i>
                </Link>
                <Link
                  to="https://www.facebook.com/share/Kd7LPz8WXxxdyLEP/?mibextid=qi2Omg"
                  target="_blank"
                  className="facebook gradient-text-color"
                >
                  <i className="bi bi-facebook"></i>
                </Link>
                <Link
                  to="https://www.instagram.com/ryientsoft_official?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                  target="_blank"
                  className="instagram gradient-text-color"
                >
                  <i className="bi bi-instagram"></i>
                </Link>
                <Link
                  to="https://www.linkedin.com/company/ryientsoft-technologies/mycompany/"
                  className="linkedin gradient-text-color"
                >
                  <i className="bi bi-linkedin"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-4">
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>RyientSoft Technologies</span>
            </strong>{" "}
            2019-{currentYear}
          </div>
          <div className="credits"></div>
        </div>
      </section>
    </>
  );
}

export default Footer;
